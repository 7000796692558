import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box, Text } from "../../components/Core";

import iconLayout from "../../assets/image/png/icon-layout.png";
import iconLayers from "../../assets/image/png/icon-layers.png";
import iconResponsive from "../../assets/image/png/icon-responsive.png";

const BoxStyled = styled(Box)`
  border-bottom: 1px solid #eae9f2;
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest} className="d-flex flex-column align-items-center">
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box className="text-center">
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    <BoxStyled bg="#f6f6f8" pb={["50px!important"]}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard
              title="Cloud-based management software"
              iconImage={iconLayout}
            >
              Our cloud-based management software allows you to access your
              system anytime, anywhere, and with any device. So, whether you’re
              on a business trip, or out attending important meetings, you can
              rest assured knowing your maintenance management software is
              working at full capacity while storing all important data
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard
              title=" Maintenance management software"
              iconImage={iconLayers}
            >
              You can also have full control over each process, using our
              prioritizing tools to focus on the most important tasks at hand.
              In fact, our CMMS software can be uniquely customized to match
              preferred operation methods with the management software.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard
              title=" Streamline business processes"
              iconImage={iconResponsive}
            >
              From accessibility to flexibility, implementation, and support,
              you’ll benefit from a full-service maintenance management software
              solution to streamline business processes and increase overall
              productivity within your organization..
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </BoxStyled>
  </>
);

export default Feature;
