import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/cmms/Content1";
import Hero from "../sections/cmms/Hero";
import Feature from "../sections/cmms/Feature";
import Testimonial from "../sections/cmms/Testimonial";
import Call from "../sections/cmms/Call";
const CMMSpage = () => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Hero />
        <Content1 />
        <Feature />
        <Testimonial />
        <Call />
      </PageWrapper>
    </>
  );
};
export default CMMSpage;
